import { styled } from '@mui/material/styles'
import { useCallback, useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box'
import { SxProps } from '@mui/material'

import { Images } from '@images'
import { colors } from '@styles'
import { useModal } from '@contexts'
import { useTempImageUploader } from '@hooks'
import { ImageCropperModal } from '@features/Digitize/modals'
import { FileProps } from '@features/Digitize/types'

import { CustomButton } from '../Buttons'
import { RoundedImage } from '../RoundedImage'
import { TempImageUploaderStatus } from '../../hooks/useTempImageUploader'

type Props = {
  label: string
  onFileChange(fileProps: FileProps): void
  multiple?: boolean
  showImages?: boolean
  buttonProps?: SxProps
  aspect?: number
  onStatusChange?: (status: TempImageUploaderStatus) => void
}

const HiddenInput = styled('input')(() => ({
  display: 'none',
}))

const ImageFileInput = ({
  label,
  onFileChange,
  showImages = true,
  buttonProps = {},
  aspect,
  onStatusChange,
}: Props) => {
  const { showModal } = useModal()

  const [fileProps, updateFileProps] = useState<FileProps | null>(null)
  const { upload, status } = useTempImageUploader(
    url => {
      if (fileProps) {
        onFileChange({ ...fileProps, url })
      }
    },
    () => {},
  )
  const inputRef = useRef<HTMLInputElement | null>(null)
  const clearInput = useCallback(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.value = ''
    }
  }, [inputRef])
  useEffect(() => {
    if (fileProps) {
      fetch(fileProps.objectUrl)
        .then(response => response.blob())
        .then(upload)
    }
  }, [fileProps, upload])

  useEffect(() => {
    if (onStatusChange) {
      onStatusChange(status)
    }
  }, [status, onStatusChange])

  return (
    <>
      <Box display={'flex'} flexDirection={'row'} gap={2} width={'100%'} flexWrap={'wrap'}>
        {showImages && !fileProps?.objectUrl && (
          <RoundedImage src={Images.Placeholder} onClick={() => inputRef?.current?.click()} />
        )}
        {showImages && !!fileProps?.objectUrl && (
          <RoundedImage src={fileProps?.objectUrl} onClick={() => inputRef?.current?.click()} />
        )}

        <CustomButton
          variant={'link'}
          label={label}
          onClick={() => inputRef?.current?.click()}
          color={colors.primary.main.background}
          sx={{ alignSelf: 'center', ...buttonProps }}
        />
      </Box>
      <HiddenInput
        ref={inputRef}
        type='file'
        accept='image/*'
        multiple={false}
        onChange={e => {
          const fileList = e.target.files
          if (fileList && fileList.length > 0) {
            const file = fileList[0]
            showModal({
              modal: (
                <ImageCropperModal
                  aspect={aspect}
                  file={{ objectUrl: URL.createObjectURL(file), size: file.size, name: file.name }}
                  onCrop={croppedFileProps => updateFileProps(croppedFileProps)}
                  onCancel={() => (inputRef.current!.value = '')}
                />
              ),
              options: {
                modalProps: { width: 500 },
                onDismiss: clearInput,
              },
            })
          }
        }}
      />
    </>
  )
}
export { ImageFileInput }
