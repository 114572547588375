export enum TriviaType {
  TRIVIA = 'TRIVIA',
  FORECAST = 'FORECAST',
}

export enum QuestionAnswerType {
  SINGLE = 'SINGLE',
  MULTIPLE = 'MULTIPLE',
}

export enum QuestionMediaType {
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
}

export enum TriviaClientStatus {
  OPENED = 'OPENED',
  STARTED = 'STARTED',
  FINISHED = 'FINISHED',
}

export type Answer = {
  id: number
  text: string
}

export type Question = {
  id: number
  text: string
  answerType: QuestionAnswerType
  mediaType: QuestionMediaType
  multimediaUrl?: string
  answers?: Answer[]
}

export type TriviaClient = {
  lastEventDate?: Date
  remainingSeconds: number
  totalQuestions: number
  correctAnswers: number
  questionAnswered: number
  status: TriviaClientStatus
  points: number
  timeout?: boolean
}
export type Trivia = {
  id: number
  imageUrl: string
  name: string
  description: string
  type: TriviaType
  startDate: Date
  endDate: Date
  enabled: boolean
  questions?: Question[]
  progress: number
  quizClient?: TriviaClient
}

export interface TriviasFilters {
  [key: string]: any | '' | undefined
}

export interface RankingTriviasFilters extends TriviasFilters {
  triviaId: number
}

export type RankingTrivia = {
  client_id: number
  position: number
  name: string
  team?: string
  correct_answers: number
  total_questions: number
  points: number
}
export const RankingTriviaComparator = (item: RankingTrivia, other?: RankingTrivia) => {
  if (item && other) {
    return item.client_id === other.client_id
  }

  return false
}
