import { Divider } from '@mui/material'
import * as React from 'react'
import { useCallback, useMemo, useState } from 'react'
import Box from '@mui/material/Box'
import dayjs from 'dayjs'
import { useFormik } from 'formik'

import { TeamPickerModal, TeamIcon } from '@features/Teams'
import {
  CustomButton,
  DateInput,
  ImageFileInput,
  Loading,
  RoundedCornersBox,
  RoundedImage,
  TextCaptionBold,
  TextInput,
  Title,
} from '@components'
import { colors } from '@styles'
import { useModal, useUser } from '@contexts'
import { TeamType } from '@types'
import { useTranslation } from '@hooks'
import { Images } from '@images'
import { AppBase } from '@features/AppBase'

import { EditProfileConfirmModal } from '../../modals'
import { FileProps } from '../../../Digitize/types'
import { Card, Content, ProfileColumn, ProfileRow } from './styled'
import { useProfileValidationSchema } from './validation'
import { ProfileDto } from '../../types'
import { useProfileEditor } from '../../hooks'
import { TempImageUploaderStatus } from '../../../../hooks/useTempImageUploader'

const ProfileMain = () => {
  const { t } = useTranslation()
  const { showModal } = useModal()
  const { userState } = useUser()
  const { updateProfile } = useProfileEditor()
  const { profileValidationSchema } = useProfileValidationSchema()

  const profile = useMemo(() => {
    return userState?.profile
  }, [userState])

  const handleSubmit = async (values: Partial<ProfileDto>) => {
    try {
      await updateProfile(values)
    } catch {}
    formik.setSubmitting(false)
  }

  const formik = useFormik<Partial<ProfileDto>>({
    initialValues: {},
    validationSchema: profileValidationSchema,
    onSubmit: async values => {
      await handleSubmit(values)
      formik.resetForm()
    },
  })

  const confirmAndSubmit = useCallback(() => {
    showModal({ modal: <EditProfileConfirmModal onConfirm={formik.submitForm} /> })
  }, [formik.submitForm])

  const [loadingImage, setLoadingImage] = useState(false)

  return (
    <AppBase requireActiveSession backgroundImage={Images.ProfileBackground}>
      <Box width={'100%'} px={{ md: 16.5, sm: 8, xs: 2 }} py={4}>
        <Title text={t('my-profile.title')} />

        <Content>
          <ProfileColumn
            width={{ lg: '50%', md: '100%', sm: '100%', xs: '100%' }}
            alignItems={'flex-start'}
            gap={2.5}
            p={2}
          >
            <ProfileRow>
              <Card>
                <Box sx={{ position: 'relative' }}>
                  <RoundedImage
                    src={
                      profile?.avatar || formik.values.avatar
                        ? formik.values.avatar ?? profile?.avatar
                        : Images.Placeholder
                    }
                    sx={{
                      borderStyle: 'solid',
                      borderWidth: 2,
                      borderColor: colors.primary.main.background,
                      backgroundColor: colors.primary.main.background,
                    }}
                  />
                  {loadingImage && (
                    <Box sx={{ position: 'absolute', top: 24, left: 24 }}>
                      <Loading />
                    </Box>
                  )}
                </Box>
              </Card>
              <ImageFileInput
                aspect={1}
                label={t('my-profile.common.edit')}
                onFileChange={(fileProps: FileProps) => {
                  formik.setFieldValue('avatar', fileProps.url)
                }}
                showImages={false}
                onStatusChange={status => setLoadingImage(status == TempImageUploaderStatus.UPLOADING)}
              />
            </ProfileRow>
            <Divider color={colors.grayscale['30'].background} sx={{ width: '100%' }} />
            <ProfileRow>
              <Card>
                {formik.values.favouriteNationalTeam?.logo || profile?.favouriteNationalTeam?.logo ? (
                  <RoundedImage
                    src={formik.values.favouriteNationalTeam?.logo ?? profile?.favouriteNationalTeam?.logo}
                  />
                ) : (
                  <TeamIcon />
                )}
                <TextCaptionBold sx={theme => ({ textAlign: 'center', mt: theme.spacing(1) })}>
                  {formik.values.favouriteNationalTeam?.name ?? profile?.favouriteNationalTeam?.name}
                </TextCaptionBold>
              </Card>
              <CustomButton
                variant={'link'}
                label={t('my-profile.common.edit')}
                color={colors.primary.main.background}
                sx={{ alignSelf: 'center' }}
                onClick={() =>
                  showModal({
                    modal: (
                      <TeamPickerModal
                        maxTeams={1}
                        type={TeamType.NATIONAL_TEAM}
                        onChange={team => {
                          formik.setFieldValue('favouriteNationalTeam', team ? team.shift() ?? null : null)
                        }}
                      />
                    ),
                  })
                }
              />
            </ProfileRow>
            <ProfileRow>
              <Card>
                {formik.values.favouriteTeam?.logo || profile?.favouriteTeam?.logo ? (
                  <RoundedImage src={formik.values.favouriteTeam?.logo ?? profile?.favouriteTeam?.logo} />
                ) : (
                  <TeamIcon />
                )}
                <TextCaptionBold sx={theme => ({ textAlign: 'center', mt: theme.spacing(1) })}>
                  {formik.values.favouriteTeam?.name ?? profile?.favouriteTeam?.name}
                </TextCaptionBold>
              </Card>
              <CustomButton
                variant={'link'}
                label={t('my-profile.common.edit')}
                color={colors.primary.main.background}
                sx={{ alignSelf: 'center' }}
                onClick={() =>
                  showModal({
                    modal: (
                      <TeamPickerModal
                        type={TeamType.TEAM}
                        onChange={team => {
                          formik.setFieldValue('favouriteTeam', team ? team.shift() ?? null : null)
                        }}
                      />
                    ),
                  })
                }
              />
            </ProfileRow>
          </ProfileColumn>

          <RoundedCornersBox
            display={'flex'}
            width={{ lg: '50%', md: '100%', sm: '100%', xs: '100%' }}
            flexDirection={'column'}
            justifyContent={'center'}
            gap={2.5}
            p={2}
          >
            <>
              <TextInput
                label={t('my-profile.input.first-name')}
                fullWidth
                name={'firstName'}
                defaultValue={profile?.firstName}
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                helperText={formik.touched.firstName && formik.errors.firstName}
              />
              <TextInput
                label={t('my-profile.input.last-name')}
                fullWidth
                name={'lastName'}
                defaultValue={profile?.lastName}
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                helperText={formik.touched.lastName && formik.errors.lastName}
              />
              <DateInput
                label={t('my-profile.input.birthdate')}
                fullWidth
                defaultValue={profile ? dayjs(profile.birthdate, 'YYYY-MM-DD') : undefined}
                name={'birthdate'}
                openTo={'year'}
                onChange={date => {
                  if (!!date) {
                    formik.setFieldValue('birthdate', date.format('YYYY-MM-DD'))
                  }
                  formik.setFieldTouched('birthdate', true, true)
                }}
                error={formik.touched.birthdate && Boolean(formik.errors.birthdate)}
                helperText={formik.touched.birthdate && formik.errors.birthdate}
              />
              <TextInput label={t('my-profile.input.email')} fullWidth defaultValue={profile?.email} disabled={true} />
              <TextInput
                label={t('my-profile.input.phone')}
                fullWidth
                defaultValue={profile?.phoneNumber}
                name={'phoneNumber'}
                value={formik.values.phoneNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
              />
              <CustomButton
                variant={'primary'}
                label={t('my-profile.common.save')}
                fullWidth={true}
                disabled={!formik.isValid || formik.isValidating || formik.isSubmitting}
                onClick={() => confirmAndSubmit()}
              />
            </>
          </RoundedCornersBox>
        </Content>
      </Box>
    </AppBase>
  )
}

export { ProfileMain }
