import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import { FormControl, FormControlLabel, FormGroup, LinearProgress, RadioGroup } from '@mui/material'
import * as React from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'

import {
  Checkbox,
  CustomButton,
  MiniCountDown,
  Radio,
  RoundedCornersBox,
  TextCaptionBold,
  TextTitleBold,
} from '@components'
import { colors } from '@styles'
import { useTranslation } from '@hooks'

import { Answer, Question, QuestionAnswerType, Trivia, TriviaClientStatus } from '../../types'

const QuestionImage = styled('img')(({ theme }) =>
  theme.unstable_sx({
    borderRadius: theme.spacing(2),
    borderColor: colors.grayscale['30'].background,
    borderWidth: 1,
    borderStyle: 'solid',
    width: { md: '50%', sm: '100%' },
    height: theme.spacing(60),
    objectFit: 'cover',
  }),
)

type PropsWithQuestion = {
  question: Question
}
type PropsWithTrivia = {
  trivia: Trivia
}
type AnswerCallback = (questionId: number, answer: Answer | Answer[], timeout: boolean) => void
type AnswerListener = { onChange: AnswerCallback }
type Props = PropsWithTrivia & AnswerListener

const QuestionMediaBox = (props: PropsWithQuestion) => {
  const { question } = props

  return <QuestionImage src={question.multimediaUrl} />
}

const SingleAnswerGroup = (props: PropsWithQuestion & AnswerListener) => {
  const { question, onChange } = props
  const answers = question.answers ?? []

  const handleOnChange = useCallback(
    (_, value: string) => {
      const answer = answers.find(a => `${a.id}` === value)
      if (!!answer) {
        onChange(question.id, answer, false)
      }
    },
    [question, onChange, answers],
  )

  return (
    <FormControl>
      {answers.length > 0 && (
        <RadioGroup onChange={handleOnChange} sx={{ gap: 2 }}>
          {answers.map(a => (
            <FormControlLabel
              value={a.id}
              control={<Radio />}
              label={a.text}
              sx={{ color: colors.grayscale['10'].background }}
            />
          ))}
        </RadioGroup>
      )}
    </FormControl>
  )
}

const MultipleAnswerGroup = (props: PropsWithQuestion & AnswerListener) => {
  const { question, onChange } = props
  const answers = question.answers ?? []

  const [selectedAnswers, setSelectedAnswers] = useState<Answer[]>([])

  const handleCheckboxChange = useCallback(
    (answer: Answer) => {
      const currentIndex = selectedAnswers.indexOf(answer)
      const newSelectedAnswers = [...selectedAnswers]

      if (currentIndex === -1) {
        newSelectedAnswers.push(answer)
      } else {
        newSelectedAnswers.splice(currentIndex, 1)
      }

      setSelectedAnswers(newSelectedAnswers)
      if (newSelectedAnswers.length > 0) {
        onChange(question.id, newSelectedAnswers, false)
      }
    },
    [question, selectedAnswers],
  )

  return (
    <FormControl>
      {answers.length > 0 && (
        <FormGroup sx={{ gap: 2 }}>
          {answers.map(a => (
            <FormControlLabel
              control={<Checkbox checked={selectedAnswers.includes(a)} onChange={() => handleCheckboxChange(a)} />}
              label={a.text}
              sx={{ color: colors.grayscale['10'].background }}
            />
          ))}
        </FormGroup>
      )}
    </FormControl>
  )
}

const TriviaProgress = (props: PropsWithQuestion & PropsWithTrivia) => {
  const { trivia, question } = props
  const questionNumber = useMemo(() => {
    return (trivia.questions?.findIndex(q => q.id === question.id) ?? 0) + 1
  }, [question])

  const questionsCount = useMemo(() => {
    return trivia.questions?.length || 1
  }, [trivia])

  return (
    <Box width={'100%'}>
      <LinearProgress variant='determinate' value={(questionNumber * 100) / questionsCount} color={'primary'} />
      <TextCaptionBold sx={{ textAlign: 'right' }}>{`${questionNumber}/${questionsCount}`}</TextCaptionBold>
    </Box>
  )
}

const QuestionDetailsBox = (props: PropsWithQuestion & PropsWithTrivia & AnswerListener) => {
  const { question, trivia, onChange } = props
  const { t } = useTranslation()
  const [answer, updateAnswer] = useState<Answer | Answer[] | null>(null)
  const isLastQuestion = useMemo(() => {
    const questions = trivia.questions ?? []
    const index = questions.findIndex(q => q.id == question.id)

    return index + 1 === questions.length
  }, [question, trivia])

  useEffect(() => {
    updateAnswer(null)
  }, [question])

  const onAnswerChange = (_: number, a: Answer | Answer[]) => {
    updateAnswer(a)
  }

  const submitAnswer = useCallback(
    (timeout = false) => {
      if (trivia.quizClient?.status !== TriviaClientStatus.FINISHED) {
        onChange(question.id, !!answer ? answer : [], timeout)
      }
    },
    [answer, question, trivia],
  )

  return (
    <Box gap={2} display={'flex'} flexDirection={'column'} width={'100%'} justifyContent={'space-between'}>
      <Box display={'flex'} flexDirection={{ xs: 'column', sm: 'row' }} gap={2} justifyContent={'space-between'}>
        <TextTitleBold>{question.text}</TextTitleBold>
        <MiniCountDown
          remainingTime={trivia.quizClient?.remainingSeconds ?? 0}
          onCountDownFinished={() => submitAnswer(true)}
        />
      </Box>
      {question.answerType === QuestionAnswerType.SINGLE ? (
        <SingleAnswerGroup question={question} onChange={onAnswerChange} />
      ) : (
        <MultipleAnswerGroup question={question} onChange={onAnswerChange} />
      )}
      <TriviaProgress question={question} trivia={trivia} />
      <CustomButton
        variant={'primary'}
        label={isLastQuestion ? t('trivias.finish') : t('trivias.next')}
        fullWidth
        disabled={!answer}
        onClick={() => submitAnswer(false)}
      />
    </Box>
  )
}

const QuestionCard = (props: Props) => {
  const { trivia, onChange } = props

  const question = useMemo(() => {
    if (!!trivia && !!trivia.quizClient) {
      const currentQuestionIndex = trivia.quizClient.questionAnswered
      if (currentQuestionIndex < (trivia.questions?.length ?? 0) && !!trivia.questions) {
        return trivia.questions[currentQuestionIndex]
      }
    }

    return null
  }, [trivia?.quizClient])

  return question ? (
    <RoundedCornersBox
      p={2}
      display={'flex'}
      flexDirection={{ xs: 'column', sm: 'column', md: 'row' }}
      gap={2}
      width={'100%'}
      alignItems={{ sm: 'center', md: 'normal' }}
    >
      <QuestionMediaBox question={question} />
      <QuestionDetailsBox question={question} trivia={trivia} onChange={onChange} />
    </RoundedCornersBox>
  ) : null
}
export { QuestionCard }
