import * as React from 'react'
import { ChangeEvent, useCallback, useRef, useState } from 'react'
import { ValidationError } from 'yup'

import { CustomButton, TextCaptionBold } from '@components'
import { colors } from '@styles'
import { useTranslation, useTempImageUploader } from '@hooks'
import { useModal } from '@contexts'

import { ImageCropperModal } from '../../modals'
import { FileProps } from '../../types'
import { useTShirtImageValidationSchema } from './validation'
import { HiddenInput, TShirtImagePickerContent, TShirtImagePickerContainer, TShirtImagePickerHeader } from './styled'
import { SelectedImage } from '../SelectedImage'
import { useDigitize } from '../../contexts/digitize-context'

interface TShirtImagePickerProps {
  title: string
  name: string
}
const TShirtImagePicker = (props: TShirtImagePickerProps) => {
  const { title, name } = props

  const { showModal } = useModal()
  const { t } = useTranslation()
  const { TShirtImageValidationSchema } = useTShirtImageValidationSchema()
  const { onImageMediaFileSelected } = useDigitize()

  const [fileProps, updateFileProp] = useState<FileProps | null>(null)
  const [errors, updateErrors] = useState<string[]>([])

  const { upload } = useTempImageUploader(
    url => onImageMediaFileSelected(name, url),
    () => updateErrors([t('digitize.image-picker.error.upload')]),
  )

  const inputRef = useRef<HTMLInputElement | null>(null)

  const clearInput = useCallback(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.value = ''
    }
  }, [inputRef])

  const handleFilePropsChange = useCallback(
    async (newFileProps: FileProps | null) => {
      updateFileProp(newFileProps)
      updateErrors([])
      if (newFileProps) {
        try {
          await TShirtImageValidationSchema.validate(newFileProps, { abortEarly: false })
          fetch(newFileProps.objectUrl)
            .then(response => response.blob())
            .then(upload)
        } catch (result) {
          if (result instanceof ValidationError) {
            updateErrors(result?.errors ?? [])
          }
        }
      }
    },
    [TShirtImageValidationSchema, upload],
  )

  const onFileChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const fileList = e.target.files
      if (fileList && fileList.length > 0) {
        const file: File = fileList[0]
        const fileProps: FileProps = { name: file.name, size: file.size, objectUrl: URL.createObjectURL(file) }
        showModal({
          modal: <ImageCropperModal file={fileProps} onCrop={handleFilePropsChange} onCancel={clearInput} />,
          options: {
            modalProps: { width: 500 },
            onDismiss: clearInput,
          },
        })
      }
    },
    [updateFileProp, showModal, clearInput],
  )

  return (
    <TShirtImagePickerContainer>
      <TShirtImagePickerHeader>
        <TextCaptionBold>{title}</TextCaptionBold>
        <CustomButton
          variant={'link'}
          sx={{ padding: 0, lineHeight: 0 }}
          onClick={() => inputRef?.current?.click()}
          label={t('digitize.image-picker.upload')}
          color={colors.primary.main.background}
        />
        <HiddenInput ref={inputRef} type='file' accept='image/*' multiple={false} onChange={onFileChange} />
      </TShirtImagePickerHeader>

      <TShirtImagePickerContent>
        {fileProps && (
          <SelectedImage
            originalFile={fileProps}
            onDelete={() => {
              handleFilePropsChange(null)
              onImageMediaFileSelected(name, '')
            }}
            errors={errors}
          />
        )}
      </TShirtImagePickerContent>
    </TShirtImagePickerContainer>
  )
}

export { TShirtImagePicker }
