import { useMutation } from 'react-query'
import { useEffect, useState } from 'react'

import { MediaApi } from '../../api/media'

enum TempImageUploaderStatus {
  IDLE,
  UPLOADING,
  SUCCESS,
  ERROR,
}

const useTempImageUploader = (onImageUploaded: (url: string) => void, onError: () => void) => {
  const [status, setStatus] = useState<TempImageUploaderStatus>(TempImageUploaderStatus.IDLE)

  const { mutate, isLoading } = useMutation(MediaApi.upload, {
    onSuccess: url => {
      setStatus(TempImageUploaderStatus.SUCCESS)
      onImageUploaded(url)
    },
    onError: () => {
      setStatus(TempImageUploaderStatus.ERROR)
      onError()
    },
  })

  useEffect(() => {
    if (isLoading) {
      setStatus(TempImageUploaderStatus.UPLOADING)
    }
  }, [isLoading])

  return { status, upload: mutate }
}

export { useTempImageUploader, TempImageUploaderStatus }
